import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useLogout from "../hooks/useLogout";

function LogoutButton() {
  const performLogout = useLogout();

  return (
    <Button onClick={performLogout}>
      <FormattedMessage id="common.logout" />
    </Button>
  );
}

function SimpleHeader({ children }: { children?: React.ReactNode }) {
  const { slots } = extractSlots(children);
  const theme = useTheme();

  return (
    <Stack width="100%">
      <Stack direction="row" ml="10px" mr="30px">
        {slots.menu}

        <Stack
          direction="row"
          sx={{ mr: "auto", ml: "1em", mb: "6px", mt: "9px" }}
        >
          <Typography
            variant="h1"
            marginRight={"0.2em"}
            color={theme.palette.primary.main}
          >
            Sehat
          </Typography>
          <Typography
            variant="h1"
            marginRight={"0.2em"}
            color={theme.palette.secondary.main}
          >
            Sanjha
          </Typography>
          <Typography variant="subtitle2" color={theme.palette.secondary.main}>
            1.5.9 (Prod)
          </Typography>
          {slots.subheader}
        </Stack>
        <LogoutButton />
      </Stack>
      <Divider color={theme.palette.primaryContainer.main} />
    </Stack>
  );
}

function MenuSlot({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}
function Subheader({ children }: { children: React.ReactNode }) {
  return <>{children}</>;
}

SimpleHeader.Menu = MenuSlot;
SimpleHeader.Subheader = Subheader;

interface SimpleHeaderSlots {
  menu?: React.ReactNode;
  subheader?: React.ReactNode;
}

function extractSlots(children: React.ReactNode) {
  const slots: SimpleHeaderSlots = {};
  const remainingChildren: React.ReactNode[] = [];

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return;
    }
    if (child.type === MenuSlot) {
      slots.menu = child;
    } else if (child.type === Subheader) {
      slots.subheader = child;
    } else {
      remainingChildren.push(child);
    }
  });
  return { slots, remainingChildren };
}

export default SimpleHeader;
